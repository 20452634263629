import * as React from "react"
import {graphql, StaticQuery, navigate} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const Invites = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("全部");

  React.useEffect(() => {
    // Read category from URL on mount
    const params = new URLSearchParams(window.location.search);
    const categoryParam = params.get('category');
    if (categoryParam) {
      setSelectedCategory(decodeURIComponent(categoryParam));
    }
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    // Update URL when category changes
    const newUrl = category === "全部" 
      ? window.location.pathname 
      : `${window.location.pathname}?category=${encodeURIComponent(category)}`;
    navigate(newUrl, { replace: true });
  };

  const getUniqueCategories = (invites) => {
    const categories = new Set(["全部"]);
    invites.forEach(invite => {
      invite.tags.forEach(tag => categories.add(tag));
    });
    return Array.from(categories);
  };

  return (
    <StaticQuery
      query={graphql`
        query InvitesQuery {
          contentYaml {
            invites {
              id
              description
              link
              introduce
              details
              name
              tags
              logo {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 50, height: 50, quality: 95)
                }
              }
            }
          }
        }
      `}
      render={data => 
        { 
          const categories = getUniqueCategories(data.contentYaml.invites);
          const filteredInvites = data.contentYaml.invites.filter(invite => 
            selectedCategory === "全部" || invite.tags.includes(selectedCategory)
          );

          return (
        <div className="">
                      <div className="flex flex-wrap gap-1 mb-3">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                  className={`px-2 py-0.5 rounded-full text-xs ${
                    selectedCategory === category
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>


          {filteredInvites.map((invite) => {

            return (
              <div key={invite.id} className="px-4 py-6 mt-4 leading-normal rounded shadow bg-white dark:bg-black">
                <div className="flex items-center justify-between">
                  <div className="flex-initial leading-5 mx-2">
                    <a href={`${invite.link}`} className="flex">
                      <div className="mr-4">
                        <GatsbyImage
                          className="w-12 rounded-full"
                          alt={invite.name}
                          image={invite.logo.childImageSharp.gatsbyImageData}
                        />
                      </div>
                      <div>
                        <h4 className="text-xl font-semibold">{invite.name}</h4>
                        <h5 className="text-gray-400 text-sm">{invite.description}</h5>
                      </div>
                    </a>
                  </div>
                  <div className="flex-none flex flex-col sm:flex-row gap-2">
                    <a className="text-white py-2 px-2 rounded-lg text-xs bg-blue-600 text-center" href={`${invite.introduce}`}>查看介绍</a>
                    <a className="text-white py-2 px-2 rounded-lg text-xs bg-blue-600 text-center" href={`${invite.link}`}>立即注册</a>
                  </div>
                </div>
                <div className="text-xs pl-2 pr-4 pt-4 text-gray-800">
                  <div className="flex flex-wrap gap-1">
                    {invite.tags.map(tag => {
                      return (
                        <span 
                          key={tag} 
                          onClick={() => handleCategoryChange(tag)}
                          className="inline-block text-white bg-gray-400 px-2 py-1 rounded text-xs cursor-pointer hover:bg-gray-500"
                        >
                          {tag}
                        </span>
                      )
                    })}
                  </div>
                  <ul className="list-disc pt-3 pl-4">
                    {invite.details.map(detail => {
                      return (
                        <li key={detail}>{detail}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
      )}
    }
    />
  )
}

export default Invites
